import React from 'react'
import ShortIntro from '@components/Sidebar/components/ShortIntro'
const Sidebar = () => {
  return (
    <>
      <ShortIntro />
    </>
  )
}

export default Sidebar
